import { useHistory } from "react-router";

const StartModal: React.FC = () => {

    const history = useHistory();
    
    return (
        <div className="modal fade" role="dialog" tabIndex={-1} id="exampleModal">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="card p-0">
                        <div className="card-body p-4">
                            <h5 className="card-title fw-light text-center">Please note</h5>
                            <p className="card-text text-center mb-0">You can continue your registration, however, your workshop will not be operational until your RMi membership number has been verified.<br/></p>
                            <div className="spacer"></div>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-outline-warning btn-lg w-100" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Back</button>
                                </div>
                                <div className="col">
                                    <button className="btn btn-warning btn-lg w-100" id="alert-modal-1" onClick={() => {
                                        history.push('rmi-error')
                                    }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">I understand.</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartModal;
