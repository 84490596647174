import React from 'react';
import "../modals/ModelStyling.scss"
import { updateBookingStatus } from '../../services/workshop.service';

const DashModal:  React.FC<any>= ({handleShowSnackbar,setSnackbarMessage, modaldata, onClose, setLoadingServices  }) => {

  const [status, setStatus] = React.useState(modaldata.status);
   // Function to handle the button click and trigger the API
   const handleStatusUpdate =(status:any) => {
     onClose();
     setLoadingServices(true)
   
    updateBookingStatus({"booking_id": modaldata.uuid, "status": status}).then((res) => {
      setStatus(status)
    }).catch((err) => {
      setSnackbarMessage("Booking status has been updated!")
      handleShowSnackbar()
      setLoadingServices(false)
    })
  };

  return (
    <div className="modal"> {/* Apply the 'modal' class */}
    <div className="modal-height">
    <div className="modal-content"> {/* Apply the 'modal-content' class */}
      <h2>Vehicle Details</h2>
        <p>Make: {modaldata.vehicle_details.make}</p>
        <p>Model: {modaldata.vehicle_details.model}</p>
        <p>Year: {modaldata.vehicle_details.year}</p>
        <p>Registration: {modaldata.registration_number}</p>
        <p>Mileage: {modaldata.milleage} KM</p>
        <p>Engine Capacity: {modaldata.vehicle_details.engine_capacity}</p>
        <p>Gearbox Type: {modaldata.vehicle_details.gearbox_type}</p>
        <p>Gearbox Drive Type: {modaldata.vehicle_details.gearbox_drive_type}</p>
        <h2>Customer Details</h2> 
        <p>Name: {modaldata.name} {modaldata.surname}</p>
        <p>Email: {modaldata.email}</p>
        <p>Phone Number: {modaldata.phone_number}</p>
        
        <h2>Part Details</h2>
        <p>Parts Needed:
          <ul>
            {modaldata.parts_needed.map((part: any, index: any) => (
              <li key={index}>
                {part.part} x {part.amount}
              </li>
            ))}
          </ul>
        </p>
        <h2>Service Details</h2>
        <p>Service Date: {modaldata.service_date}</p>
        <p>Status: {modaldata.status}</p>
        <h2>Select Booking Status</h2>

        {/* {status === "pending" ? (
        <div className="modal-accepted-container"> 
        <button
          style={{ background: "green" }}
          className="modal-button"
          onClick={() => handleStatusUpdate("Accepted")}
        >
          Accept Booking
        </button>
      
        <button
          style={{ background: "#C70039" }}
          className="modal-button"
          onClick={() => handleStatusUpdate("Rejected")}
        >
          Reject Booking
        </button>
        </div>
        ):( */}
          <div className="modal-header"> {/* Create a header section for the buttons */}
          <button
            style={{ background: "#aba057" }}
            className="modal-button"
            onClick={() => handleStatusUpdate("In Progress")}
          >
            In Progress
          </button>
          <button
            style={{ background: "#30A029" }}
            className="modal-button"
            onClick={() => handleStatusUpdate("Completed")}
          >
            Completed
          </button>
          {/* <button
            style={{ background: "#C70039" }}
            className="modal-button"
            onClick={() => handleStatusUpdate("Rejected")}
          >
            Rejected
          </button> */}
          <button
            className="modal-button"
            onClick={() => handleStatusUpdate("pending")}
          >
            Pending
          </button>
          </div>
        {/* )} */}

        <p className="disclaimer">
          Selecting an option above will send an update email to the customer.
        </p>

        <button className="modal-close-button" onClick={onClose}>Close</button> {/* Apply a custom class for the close button */}
      </div>
    </div>

    </div>
  );
};


export default DashModal;