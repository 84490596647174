import "./DashHeader.scss";
import Logo from "../../assets/img/servisor-logo-orange.svg";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { user } from "../../services/data.service";

const DashHeader: React.FC<any> = () => {
  const [userInfo, setUser]: any = useState(null);

  useEffect(() => {
    user.subscribe((userData: any) => {
      if (userData) {
        setUser(userData);
      }
    });
  }, []);
  return (
    <>
      <nav className="navbar navbar-light fixed-top bg-white">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/signin">
            <img className="pl-2" src={Logo} style={{ height: "20px" }} />
          </Link>
          <div className="header-main-search-container">
            {/* <div className="input-group search-input">
              <span className="input-group-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="bi bi-search"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Registration number or booking name"
              /> 
            </div> */}
            {userInfo ? (
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink replace className="nav-link active" to="/profile">
                    <span className="online-status-dot"></span>
                    {userInfo.name} {userInfo.surname}
                  </NavLink>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
};

export default DashHeader;
