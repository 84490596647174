import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { forgotPassword } from "../services/auth.service";

const ForgotForm: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailerror, setEmailError] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [responseText, setResponseText] = useState(null);
  const emailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  const registerOrigin = `${window.location.origin}/register`
  const origin= `${window.location.origin}`

  const requestReset = () => {
    forgotPassword({ email: email, url: origin })
      .then((forgotPasswordRequest: any) => {
        setResponseText(forgotPasswordRequest.msg);
      })

      .catch((error: any) => {
        setMainError(error.message);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <h5>Enter your email to reset your password.</h5>
          <form>
            <div>
              <label className="form-label fw-bolder">Email Address</label>
              <input
                type="email"
                className="form-control"
                onChange={(ev) => {
                  setEmail(ev.target.value);
                  const found = ev.target.value.match(emailpattern);
                  if (found) {
                    setEmailError(false);
                  } else {
                    setEmailError(true);
                  }
                }}
                value={email}
              />
            </div>
            {emailerror ? (
              <p className="text-danger">
                <small>Please enter a valid email.</small>
              </p>
            ) : null}
              {responseText ? (
              <p className="">
                <small>{responseText}</small>
              </p>
            ) : null}
          </form>
          <div className="spacer"></div>
          {mainError ? <p className="text-danger">{mainError}</p> : null}
          <div className="row">
            <div className="col">
              <button
                disabled={!email}
                onClick={() => requestReset()}
                className="btn btn-warning btn-lg w-100"
                type="button"
              >
                Reset Password
              </button>
            </div>
          </div>
          <div className="spacer"></div>
          <p className="card-text m-0">
            Don&#39;t have an account? 
            <a target="_blank" href={registerOrigin}>
              Register here
            </a>
            <br />
          </p>
          <div className="spacer"></div>
        </div>
      </div>
    </>
  );
};

export default ForgotForm;
