import './Header.scss';
import Logo from '../../assets/img/servisor-logo-orange.svg';

const Header: React.FC<any> = ({step, stepper}) => {
  return (
    <div>
      <nav className="navbar navbar-light navbar-expand-md bg-white">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={Logo} style={{'height': '30px'}} />
            </a>
            {
              stepper ? (
                <div className="stepper-breadcrumb">
                    <div className={step > 0 ? 'stepper-item active' : 'stepper-item'}>
                      <span className="span">1</span><span>Workshop Setup</span><span></span>
                    </div>
                    <div className={step > 1 ? 'stepper-item active' : 'stepper-item'}>
                      <span className="span">2</span><span>Operating Conditions</span><span></span>
                    </div>
                    {/* <div className={step > 2 ? 'stepper-item active' : 'stepper-item'}>
                      <span className="span">3</span><span>Business Details</span>
                    </div> */}
                </div>
              ) : null
            }
          </div>
      </nav>
    </div>
  );
};

export default Header;
