import { LIST, POST, PUT, GET, DELETE,} from "./api.service" 

export const saveOnboarding = (data: any) => {
    return POST(`onboarding/workshop`, data);
}
export const checkStatus = (id: string) => {
    return LIST(`onboarding/status/${id}`);
}

export const confirmemail = () => {
    return POST(`workshop/confirm-workshop-email`, {});
}

export const putWorkshop = (id: string, data: any) => {
    return PUT(`workshop`, data);
}

export const viewBookings = () => {
    return GET("workshop/view-bookings", {});
}

export const updateBookingStatus = (data: any) => {
    return PUT(`workshop/update-booking-status`, data);
}

export const updateWorkshopDetails = (data: any) => {
    return PUT(`workshop/edit-workshop-details`, data);
}

export const updateWorkshopHours = (data: any) => {
    return PUT(`workshop/edit-workshop-hours`, data);
}

export const getWorkshopDetails = () => {
    return GET(`workshop/get-workshop-details`, {});
}

export const updateBankingDetailsRequest = (data: any) => {
    return PUT(`workshop/banking-details-change-request`, data);
}

export const createUser = (data: any) => {
    return POST(`workshop/create-user`, data);
}

export const confirmUserEmailRequest = (data: any) => {
    return POST('workshop/confirm-user-email', data);

}

export const parseBankingDetails = (data: any) => {
    return POST(`workshop/parse-banking-details`, data);
}

export const updateBanking = (data: any) => {
    return PUT(`workshop/edit-banking-details`, data);
}
