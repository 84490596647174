import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signin } from "../services/auth.service";
import { signInAdmin } from "../services/admin.service";

const SignInForm: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailerror, setEmailError] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [showError, setShowError] = useState(false);
  const emailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  useEffect(() => {}, []);

  const doSignin = () => {
    signInAdmin({ email: email, password: password })
      .then((signInResponse: any) => {

        console.log(signInResponse, "signinreposne")

        if (signInResponse.msg){
          setMainError(signInResponse.msg)
          setShowError(true)
          return('error')
        } else{
          window.localStorage.setItem(
            "Authorization",
            signInResponse.auth_key
          );
          window.localStorage.setItem(
            "user",
            signInResponse.user_data.role
          );
          window.localStorage.setItem("email", signInResponse.user_data.email);
          window.localStorage.setItem(
              "user",
              "admin"
            );
          history.replace("/manage-workshops");
          window.location.reload()
        }

      
      })
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <h5>Sign in to your admin account</h5>
          <form>
            <div>
              <label className="form-label fw-bolder">Email Address</label>
              <input
                type="email"
                className="form-control"
                onChange={(ev) => {
                  setEmail(ev.target.value);
                  const found = ev.target.value.match(emailpattern);
                  if (found) {
                    setEmailError(false);
                  } else {
                    setEmailError(true);
                  }
                }}
                value={email}
              />
            </div>
            {emailerror ? (
              <p className="text-danger">
                <small>Please enter a valid email.</small>
              </p>
            ) : null}
            <div>
              <label className="form-label fw-bolder">Password</label>
              <input
                type="password"
                className="form-control"
                onChange={(ev) => setPassword(ev.target.value)}
                value={password}
              />
            </div>
          </form>
          <Link to="/forgot" className="card-link">
            Forgot Password?
          </Link>
          <div className="spacer"></div>
          {showError ? <p className="text-danger">{mainError}</p> : null}
          <div className="row">
            <div className="col">
              <button
                disabled={!email || !password}
                onClick={() => doSignin()}
                className="btn btn-warning btn-lg w-100"
                type="button"
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="spacer"></div>
          {/* <p className="card-text m-0">
            Don&#39;t have an account? 
            <a target="_blank" href="https://onboarding.servisor.co.za">
              Register here
            </a>
            <br />
          </p> */}
          <div className="spacer"></div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
