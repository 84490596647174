import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../../components/header/Header";
import StartModal from "../../../components/modals/StartModal";
import "./ManageInvoices.scss";

import LandingImage from "../../assets/img/illustration-placeholder.svg";
import { useHistory } from "react-router";
import { useEffect, useRef, useState } from "react";
import { getRmi } from "../../../services/rmi.service";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";

import moment from "moment";

import {
  Grid,
  GridColumn as Column,
  GridCellProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { workshop } from "../../../services/data.service";
import { getInvoices } from "../../../services/invoices.service";
import {
  getAdmininvoices,
  getServiceinvoices,
  updateService,
} from "../../../services/services.service";
// import { upload } from "../../../services/upload.service";
import DashModal from "../../../components/modals/DashModal";

const ManageInvoices: React.FC = () => {
  const fileInput = useRef(null);

  const history = useHistory();
  const [invoices, setManageInvoices]: any = useState();
  const [workshopInfo, setWorkshopInfo]: any = useState([]);
  const [activeId, setActiveId] = useState();

  const [showModal, setShowModal]: any = useState(false);
  const [modaldata, setModaldata]: any = useState(false);

  const titleCaseWord = (word: string) => {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  };
  useEffect(() => {
    workshop.subscribe((workShopDetails: any) => {
      console.log(workShopDetails);
      if (workShopDetails) {
        setWorkshopInfo(workShopDetails);
        getAllManageInvoices(workShopDetails._id.$oid);
      }
    });
  }, []);

  const getAllManageInvoices = (id: string) => {
    getAdmininvoices().then((adminResponse: any) => {
      console.log(adminResponse);
      setManageInvoices(adminResponse);
    });
  };

  const handleFileInput = (event: any) => {
    const file = event.target.files[0];
    // upload(file, "invoice", workshopInfo._id.$oid)
    //   .then((uploadResponse: any) => {
    //     console.log(uploadResponse);
    //     const params = {
    //       _id: {
    //         $oid: activeId,
    //       },
    //       invoice_url: uploadResponse.url,
    //     };
    //     updateServiceUrl(params);
    //   })
    //   .catch((d: any) => {
    //     console.log(d);
    //   });
  };

  const updateServiceUrl = (data: any) => {
    updateService(data)
      .then((response: any) => {
        getAllManageInvoices(workshopInfo._id.$oid);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const ShowModal = (data: any) => {
    setModaldata(data);
    setShowModal(true);
  };

  const modalEevent = (data: any) => {
    setModaldata(null);
    setShowModal(false);
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      d="M13,0 C14.6568542,-3.04359188e-16 16,1.34314575 16,3 L16,13 C16,14.6568542 14.6568542,16 13,16 L3,16 C1.34314575,16 2.02906125e-16,14.6568542 0,13 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L13,0 Z M13,9 L3,9 L3,11 L13,11 L13,9 Z M13,5 L3,5 L3,7 L13,7 L13,5 Z"
                      id="Combined-Shape"
                    ></path>
                  </svg>
                  <span>Manage Invoices</span>
                </h6>
              </div>
              <div className="content-container">
                {invoices ? (
                  <Grid
                    style={{ height: "100%" }}
                    data={invoices}
                    onRowClick={(e: any) => {
                      console.log(e);
                      ShowModal(e.dataItem);
                    }}
                  >
                    <Column field="serv_booking_id" title="ID" />
                    <Column
                      title="Service Date"
                      field="services_info.service_date"
                      cell={(props: GridCellProps) => (
                        <td>
                          {moment(
                            new Date(
                              props.dataItem.services_info.service_date.$date
                            ),
                            "YYYY-MM-DD",
                            true
                          ).format("dddd Do MMM")}
                        </td>
                      )}
                    />
                    <Column
                      field="vehicle.registration_number"
                      title="Reg Nr."
                    />
                    <Column
                      field="market_price.market_price.vehicle_make"
                      title="Make"
                    />
                    <Column
                      field="market_price.market_price.vehicle_model"
                      title="Model"
                    />
                    <Column
                      field="service_status"
                      title="Status"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "right" }}>
                          {titleCaseWord(props.dataItem.service_status)}
                        </td>
                      )}
                    />
                    <Column
                      field="service.price"
                      title="Invoice"
                      cell={(props: GridCellProps) => (
                        <td style={{ textAlign: "right" }}>
                          <a
                            className="pointer"
                            onClick={() => {
                              window.open(props.dataItem.invoice_url, "_blank");
                            }}
                          >
                            View Invoice
                          </a>
                        </td>
                      )}
                    />
                  </Grid>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <input
            ref={fileInput}
            className="hidden-input"
            type="file"
            onChange={(ev) => handleFileInput(ev)}
          />
          {showModal ? (
            <DashModal
              data={modaldata}
              type="booking"
              onModalEevent={modalEevent}
              showActions={false}
            />
          ) : null}
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default ManageInvoices;
