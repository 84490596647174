import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home/Home';

/* Core SCSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
 
/* Basic SCSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional SCSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/bootstrap.css';
import './theme/variables.scss';
import './theme/main.scss';
import RMISuccess from './pages/RMISuccess/RMISuccess';
import RMIError from './pages/RMIError/RMIError';
import Register from './pages/Register/Register';
import Onboarding from './pages/Workshop/Onboarding';
import WorkshopConfirm from './pages/WorkshopConfirm/WorkshopConfirm';
import Onboarded from './pages/Onboarded/Onboarded';
import ConfirmEmail from './pages/ConfirmedEmail/ConfirmedEmail';
import Signin from "./pages/Auth/Signin/Signin";
import Forgot from "./pages/Auth/Forgot/Forgot";
import Reset from "./pages/Auth/Reset/Reset";
import Diary from "./pages/Dashboard/Diary/Diary";
import Bookings from "./pages/Dashboard/Bookings/Bookings";
import Invoices from "./pages/Dashboard/Invoices/Invoices";
import Workshop from "./pages/Dashboard/Workshop/Workshop";
import Business from "./pages/Dashboard/Business/Business";
import Profile from "./pages/Dashboard/Profile/Profile";
import Users from "./pages/Dashboard/Users/Users";
import ManageUsers from "./pages/Admin/ManageUsers/ManageUsers";
import ManageWorkshops from "./pages/Admin/ManageWorkshops/ManageWorkshops";
import ManageInvoices from "./pages/Admin/ManageInvoices/ManageInvoices";
import NotFound from "./pages/NotFound/NotFound";
import Settings from "./pages/Dashboard/Settings/Settings";
import AdminSignIn from "./pages/Auth/Signin/AdminSignIn";
import ManagePayments from "./pages/Admin/ManagePayments/ManagePayements";
import ManageBookings from "./pages/Admin/ManageBookings/ManageBookings";
import CreateProfile from './pages/Profile/CreateProfile';
import AdminProfile from './pages/Profile/AdminProfile';
import ConfirmAdminEmail from './pages/ConfirmedEmail/ConfirmAdminEmail';
import ConfirmUserEmail from './pages/ConfirmedEmail/ConfirmUserEmail';
import updateBankingDetails from './pages/Profile/UpdateBankingDetails';



const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        {/* <Route exact path="/home" component={Home}></Route>
        <Route exact path="/rmi-error/:id" component={RMIError}></Route>
        <Route exact path="/rmi-success" component={RMISuccess}></Route> */}
        <Route exact path="/register" component={Register}></Route>
         <Route path="/onboarding/:id" component={Onboarding}></Route>
        <Route path="/confirmemail" component={ConfirmEmail}></Route>
        <Route path="/confirmUserEmail" component={ConfirmUserEmail}></Route>
        <Route path="/confirmAdminEmail" component={ConfirmAdminEmail}></Route>
        <Route exact path="/confirm" component={WorkshopConfirm}></Route>
        <Route exact path="/onboarded" component={Onboarded}></Route>
        {/* <Route exact path="/">
          <Redirect to="/register" />
        </Route> */}
        <Route path="/signin" exact={true} component={Signin}></Route>
        <Route path="/admin-signin" exact={true} component={AdminSignIn}></Route>
        <Route path="/forgot" exact={true} component={Forgot}></Route>
        <Route path="/reset" exact={true} component={Reset}></Route>
        <Route path="/diary" exact={true} component={Diary}></Route>
        <Route path="/bookings" exact={true} component={Bookings}></Route>
        <Route path="/invoices" exact={true} component={Invoices}></Route>
        <Route path="/workshop" exact={true} component={Workshop}></Route>
        <Route path="/business" exact={true} component={Business}></Route>
        <Route path="/profile" exact={true} component={Profile}></Route>
        <Route path="/settings" exact={true} component={Settings}></Route>
        <Route path="/add-user" exact={true} component={Users}></Route>
        <Route path="/create-profile" exact={true} component={CreateProfile}></Route>
        <Route path="/admin-create-profile" exact={true} component={AdminProfile}></Route>
        <Route path="/update-banking-details" exact={true} component={updateBankingDetails}></Route>
        <Route
          path="/manage-users"
          exact={true}
          component={ManageUsers}
        ></Route>
        <Route
          path="/manage-workshops"
          exact={true}
          component={ManageWorkshops}
        ></Route>
           <Route
          path="/manage-bookings"
          exact={true}
          component={ManageBookings}
        ></Route>
           <Route
          path="/manage-Payments"
          exact={true}
          component={ManagePayments}
        ></Route>
        <Route
          path="/manage-invoices"
          exact={true}
          component={ManageInvoices}
        ></Route>
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        <Route path="/not-found" exact={false} component={NotFound}></Route>
        <Redirect to="/not-found" />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
