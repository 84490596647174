import { LIST, CUSTOMPUT, GET , PUT, POST} from "./api.service" 

export const getUser = () => {
    return GET('workshop/get-user-profile', {});
}
export const updateUser = (data: any) => {
    return PUT('workshop/update-user-profile', data);
}

export const getBankingInfo = () => {
    return GET('workshop/get-banking-details', {});
}

export const addUser = (data: any) => {
    return POST('workshop/create-user-request', data)}