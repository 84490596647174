import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signin } from "../services/auth.service";
import { signInAdmin } from "../services/admin.service";

const SignInForm: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailerror, setEmailError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [mainError, setMainError]:any = useState(null);
  const emailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  const origin = `${window.location.origin}/register`

  useEffect(() => {}, []);

  const doSignin = () => {
   
          signin({ email: email, password: password }).then((signInResponse: any) => {
            console.log(signInResponse, "signinreposne")

            if (signInResponse.msg){
              setMainError(signInResponse.msg)
              setShowError(true)
            }
            else if (signInResponse.auth_key && signInResponse.user_data.active === true) {

            window.localStorage.removeItem("Authorization");
            window.localStorage.removeItem("user");
            window.localStorage.removeItem("workshop_id");
            
           

            window.localStorage.setItem(
              "Authorization",
              signInResponse.auth_key
              
            );
            window.localStorage.setItem(
              "user",
              signInResponse.user_data.role
            );
            window.localStorage.setItem(
              "workshop_id",
              signInResponse.user_data.workshop_id
            );

       
            history.replace("/bookings");
            window.location.reload()
            
          }})
          // .catch((error: any) => {
          //   if (JSON.stringify(error) === "{}"){
          //     if (error.status === true) {
          //     setMainError("Need to verify your email address. Please check your email for the verification link.");
          //   } else {
          //     setMainError("Invalid Login Credentials.")
          //     console.log(JSON.stringify(error))
          //   }
          // }});
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <h5>Sign in to your account</h5>
          <form>
            <div>
              <label className="form-label fw-bolder">Email Address</label>
              <input
                type="email"
                className="form-control"
                onChange={(ev) => {
                  setEmail(ev.target.value);
                  const found = ev.target.value.match(emailpattern);
                  if (found) {
                    setEmailError(false);
                  } else {
                    setEmailError(true);
                  }
                }}
                value={email}
              />
            </div>
            {emailerror ? (
              <p className="text-danger">
                <small>Please enter a valid email.</small>
              </p>
            ) : null}
            <div>
              <label className="form-label fw-bolder">Password</label>
              <input
                type="password"
                className="form-control"
                onChange={(ev) => setPassword(ev.target.value)}
                value={password}
              />
            </div>
          </form>
          <Link to="/forgot" className="card-link">
            Forgot Password?
          </Link>
          <div className="spacer"></div>
          {showError ? <p className="text-danger">{mainError}</p> : null}
          <div className="row">
            <div className="col">
              <button
                disabled={!email || !password}
                onClick={() => doSignin()}
                className="btn btn-warning btn-lg w-100"
                type="button"
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="spacer"></div>
          <p className="card-text m-0">
            <p>
              Don't have an account?{" "}
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => history.push("/register")}
              >
                Register here
              </button>
            </p>
            <br />
          </p>
          <div className="spacer"></div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
