import {
  IonAlert,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Settings.scss";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { resetPassword } from "../../../services/auth.service";

const Settings: React.FC = () => {
  const history = useHistory();
  const [user, setUser]: any = useState();
  const [clicked, setClicked]: any = useState(false);
  const [saveMessage, setSaveMessage]: any = useState(null);
  const [showAlert, setShowAlert]: any = useState(false);
  const [passError, setPassError] = useState(false);

  // useEffect(() => {
  //   setUser({
  //     password: null,
  //     confirmpassword: null,
  //   });
  // }, []);

  // const saveProfile = () => {
  //   if (user.password !== user.confirmpassword) {
  //     // alert message
  //     setShowAlert(true);
  //   } else {
  //     setClicked(true);
  //     resetPassword({email: window.localStorage.getItem("email")})
  //       .then((response: any) => {
  //         setClicked(false);
  //         setUser({
  //           password: null,
  //           confirmpassword: null,
  //         });
  //         setSaveMessage("A reset password confirmation email has been sent.");
  //         setTimeout(() => {
  //           setSaveMessage(null);
  //         }, 3000);
  //       })
  //       .catch((e: any) => {
  //         setClicked(false);
  //         setSaveMessage("Something went wrong, please try again.");
  //       });
  //   }
  // };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />

          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Oops..."}
            message={"Your passwords don't seem to match."}
            buttons={["Try Again"]}
          />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-gear-fill"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"
                    ></path>
                  </svg>
                  <span>Settings</span>
                </h6>
              </div>
              {/* {user ? ( */}
                <div className="content-container grey-bg">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Below you can change your password.
                          </h6>
                          <div className="spacer"></div>
                  
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        window.location.href = "/forgot";
                                      }}
                                    >
                                      Forgot Password
                                    </button>
                                 
                          {/* <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Password
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                defaultValue={user.password}
                                onInput={(ev: any) => {
                                  user.password = ev.target.value;
                                  setUser(user);
                                  if (user.password !== user.confirmpassword) {
                                    setPassError(true);
                                  } else {
                                    setPassError(false);
                                  }
                                }}
                              />
                            </div>
                            <p className="text-danger">
                              {passError ? (
                                <small>Your passwords do not match.</small>
                              ) : null}
                            </p>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Confirm Password
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                defaultValue={user.confirmpassword}
                                onInput={(ev: any) => {
                                  user.confirmpassword = ev.target.value;
                                  setUser(user);
                                  if (user.password !== user.confirmpassword) {
                                    setPassError(true);
                                  } else {
                                    setPassError(false);
                                  }
                                }}
                              />
                            </div>
                            <p className="text-danger">
                              {passError ? (
                                <small>Your passwords do not match.</small>
                              ) : null}
                            </p>
                            {saveMessage ? (
                              <div className="alert alert-warning">
                                <small>{saveMessage}</small>
                              </div>
                            ) : null}
                            <button
                              disabled={
                                clicked ||
                                !user.password ||
                                !user.confirmpassword
                              }
                              className="btn btn-warning btn-lg w-100"
                              type="button"
                              onClick={() => {
                                saveProfile();
                              }}
                            >
                              Save
                              {clicked ? (
                                <IonSpinner name="crescent"></IonSpinner>
                              ) : null}
                            </button>
                          </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/* ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="k-loading-image" />
                </div>
              )} */}
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Settings;
