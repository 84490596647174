import { PUT, GET , POST} from "./api.service" 

export const signup = (data: any) => {
    return POST('workshop/register-workshop-user', data);
}
export const checkEmail = (data: any) => {
    return PUT('workshop/check-duplicate-user', data);
}
export const signin = (data: any) => {
    return POST('workshop/login-workshop-user', data);
}

export const resetPassword = (data: any) => {
    return PUT('workshop/reset-password', data);
}
export const forgotPassword = (data: any) => {
    return POST('workshop/request-password-reset', data);
}