import { IonContent, IonPage, IonSpinner  } from "@ionic/react";
import Lottie from "react-lottie";
import Header from "../../components/header/Header";
import animationData from "../../assets/lottie/Workshop_Hero_Right.json";
import { useEffect, useState } from "react";
import { createAdminUser } from "../../services/admin.service";

const AdminProfile = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    window.localStorage.setItem(
      "Authorization",
      String(token)
    )
  }, []);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setShowConfirmPasswordError]: any = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("");

  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [clicked, setClicked] = useState(false);


  const nameHandler = (e: any) => {
    setName(e.target.value);
  };

  const surnameHandler = (e: any) => {
    setSurname(e.target.value);
  };

  const phoneNumberHandler = (e: any) => {
    setPhoneNumber(e.target.value);

    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(e.target.value)) {
      setPhoneError(true);
      setPhoneErrorMsg("Please enter a valid phone number");
    } else {
      setPhoneErrorMsg("");
    }
  };

  
  const passwordHandler = (e: any) => {
    const newPassword = e.target.value;
    const passwordPattern = /^(?=.*\d{3,})(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/;
    setPassword(newPassword);
  
    if (!passwordPattern.test(newPassword)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  
  const confirmPasswordHandler = (e: any) => {
    setConfirmPassword(e.target.value);

    if (e.target.value !== password) {
      setConfirmPasswordErrorMsg("Passwords do not match");
      setShowConfirmPasswordError(true);
    } else {
      setShowConfirmPasswordError(false);
    }
  };

  const createProfile = (e: any) => {
    e.preventDefault();

    setNameError("");
    setSurnameError("");
    setPhoneNumberError("");
    setPasswordError(false);
    setShowConfirmPasswordError("");

    if (name === "") {
      setNameError("Please enter name");
      return;
    }

    if (surname === "") {
      setSurnameError("Please enter surname");
      return;
    }

    if (phone_number === "") {
      setPhoneNumberError("Please enter phone number");
      return;
    }

    if (password === "") {
      setPasswordErrorMsg("Please enter password");
      return;
    }


    if (password !== confirmPassword) {
      setShowConfirmPasswordError("Passwords do not match");
      return;
    }

    const profileData = {
      "name": name,
      "surname": surname,
      "password":password,
      "phone_number":phone_number,
      "url": window.location.origin
    };

    try{
      setClicked(true);
      createAdminUser(profileData).then((res) => {
        console.log(res);
        setSuccess(true)
        setClicked(false);
      });
    }catch(e:any){
      setClicked(false);
      console.log(e)
      setErrorMessage(e.msg)
      setFailure(true)
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <Header />
        <div className="container">
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
              <div style={{ padding: "50px" }}>
                <Lottie options={defaultOptions} />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 ">
              <h5>Welcome to Servisor! Please enter the below details to register your account</h5>
              <form onSubmit={createProfile}>
                <div>
                  <label className="form-label fw-bolder">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={nameHandler}
                  />
                </div>
                {nameError && <p style={{color: 'red'}}>{nameError}</p>}
                <div className="spacer"></div>
                <div>
                  <label className="form-label fw-bolder">Surname</label>
                  <input
                    type="text"
                    className="form-control"
                    value={surname}
                    onChange={surnameHandler}
                  />
                </div>
                {surnameError && <p style={{color: 'red'}}>{surnameError}</p>}
                <div className="spacer"></div>

                <div>
                  <label className="form-label fw-bolder">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={phone_number}
                    onChange={phoneNumberHandler}
                  />
                </div>
                {phoneError && (
                  <p style={{color: 'red'}}>{phoneErrorMsg}</p>
                )}
                <div className="spacer"></div>

                <div>
                  <label className="form-label fw-bolder">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={passwordHandler}
                  />
                </div>
                {passwordError && (
                  <p className="text-danger">
                    <small>
                      Password must contain at least 6 characters, including at
                      least one numeric digit, one special character, and one
                      uppercase letter.
                    </small>
                  </p>
                )}

                <div className="spacer"></div>

                <div>
                  <label className="form-label fw-bolder">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={confirmPasswordHandler}
                  />
                </div>
                {confirmPasswordError && (
                  <p style={{color: 'red'}}>
                   <small>{confirmPasswordErrorMsg}</small>
                  </p>
                )}

                <div className="spacer"></div>

                <div className="row">
                  <div className="col">
                    <button
                      disabled={
                        !name ||
                        !surname || 
                        !phone_number ||
                        !password || 
                        !confirmPassword ||
                        password !==confirmPassword ||
!!passwordError
                      
                      }

                      className="btn btn-warning btn-lg w-100"
                      type="submit"
                    >
                      Create Profile{" "}
                      {clicked ? (
                          <IonSpinner name="crescent"></IonSpinner>
                        ) : null}
                    </button>
                    {success && (
                      <p style={{ color: "green", padding:"20px" }}>Your account has been registered! , A confirmation email has been sent to your email address.</p>
                    )}
                    {failure && (
                      <p style={{ color: "red",padding:"20px" }}>{errorMessage}</p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AdminProfile;
