import { IonButton, IonContent, IonPage } from "@ionic/react";
import Header from "../../components/header/Header";
import "./ConfirmedEmail.scss";

import Cog from "../../assets/img/dash-icon-settings-white.svg";
import { useLocation } from "react-router-dom";
import { confirmemail } from "../../services/workshop.service";

import { useHistory } from "react-router";
import { useEffect, useState } from "react";

const ConfirmEmail: React.FC = () => {
  const history = useHistory();
  const [status, setStatus] = useState({
    email: "",
    status: "",
  });

  const [email, setEmail] = useState('')

  const [message, setMessage] = useState('')
 
  const location = useLocation();
  // useEffect(() => {
  //   window.localStorage.removeItem("workshop");
  //   window.localStorage.removeItem("rmiDetails");
  //   window.localStorage.removeItem("Authorization");
  //   window.localStorage.removeItem("business");
  //   const onboardingStatus = JSON.parse(
  //     // @ts-ignore
  //     window.localStorage.getItem("servi_status")
  //   );
  //   if (onboardingStatus) {
  //     setStatus(onboardingStatus);
  //   } else {
  //     history.push("/");
  //   }
  // }, []);


useEffect(() => {
    

  const token = new URLSearchParams(location.search).get('token');
   window.localStorage.setItem(
          "Authorization",
          String(token)
        )

  confirmemail().then((res) => {
      console.log(res, "res")
      setMessage(res.msg)
  }).catch((err) => {
      console.log(err, "err")
      setMessage(err.msg)
  })
}, [])

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header />
          <div className="onboarded-container">
            <img src={Cog} className="settings-cog"  alt="confirm"/>
            <div className="text-center">
              <h3>
              {  (message ? message : "Something went wrong, Please Refresh the page.")}
              </h3>
              <h6> 
                
              </h6>
              <div className="pl-1 pr-1 mt-4">

              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ConfirmEmail;
