import React, { useEffect, useState } from 'react';
import "../modals/ModelStyling.scss"
import { updateBookingStatus } from '../../services/workshop.service';
import { getWorkshopById } from '../../services/admin.service';

const ViewWorkshopModal:  React.FC<any>= ({ data, onClose }) => {

  return (
    <div className="modal"> {/* Apply the 'modal' class */}
    <div className="modal-height">
    <div className="modal-content"> {/* Apply the 'modal-content' class */}
      <h2>Workshop Details</h2>

      <p>Workshop Name: {data.workshop_name}</p>
        <p>Address: {data.address}</p>
        <p>Province: {data.province.name}</p>
        <p>Registration: {data.reg_no}</p>
        <p>Workshop Id: {data._id}</p>
        <h2>Contact Details</h2>
        <p>Creator Name: {data.name} {data.surname}</p>
        <p>Workshop Email: {data.email}</p>
        <p>Phone Number: {data.phone_number}</p>
        <h2>Banking Details</h2>
        <p>Bank: {data.bank.name}</p>
        <p>Bank Account Number: {data.bank_account_no}</p>
        <p>Bank Account Type: {data.bank_account_type.name}</p>
        <p>Bank Branch Code: {data.bank_branch_code}</p>
        <p>VAT Number: {data.vat_no}</p>
        <h2>Business Details</h2>
        <p>RMI: {data.RMI}</p>
        <p>Rate per hour in Rands: {data.rate}</p>
        <p>Rate Discount: {data.rate_discount}%</p>
        <p>Parts Discount: {data.parts_discount}%</p>
        <p>Servicing cars newer than 10 years?: {String(data.new_cars)}</p>
        <p>Servicing cars older than 10 years?: {String(data.old_cars)}</p>
        <p>Active: {String(data.active)}</p>
        <p>Confirmed Email?: {String(data.email_confirmation)}</p>
      

        <button className="modal-close-button" onClick={onClose}>Close</button> {/* Apply a custom class for the close button */}
      </div>
    </div>

    </div>
  );
};


export default ViewWorkshopModal;