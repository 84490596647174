import { IonContent, IonPage, IonSpinner  } from "@ionic/react";
import Lottie from "react-lottie";
import Header from "../../components/header/Header";
import animationData from "../../assets/lottie/Workshop_Hero_Right.json";
import { useEffect, useState } from "react";
import { parseBankingDetails, updateBanking } from "../../services/workshop.service";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar"



const UpdateBankingDetails = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [bankingDetails, setBankingDetails] = useState<any>({})
  const [clicked, setClicked] = useState(false);
  const [bank, setBank] = useState("")
  const [bankAccountType, setBankAccountType] = useState("")

  const [workshopName, setWorkshopName] = useState("")
  const [workshopEmail, setWorkshopEmail] = useState("")
  const [workshopPhone, setWorkshopPhone] = useState("")
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    window.localStorage.setItem(
      "Authorization",
      String(token)
    )

    parseBankingDetails({}).then((res) => {
      
        setBankingDetails(res.results.data)
        setBank(res.results.data.bank.name)
        setBankAccountType(res.results.data.bank_account_type.name)
        setWorkshopName(res.workshop.workshop_name)
        setWorkshopEmail(res.workshop.email)
        setWorkshopPhone(res.workshop.phone_number)
       
        
    })
  }, []);

  const sendUpdateRequest = (status:boolean) => {
    setClicked(true);
    updateBanking({is_rejected:status,email:workshopEmail, workshop_name: workshopName}).then((res) => {    });
    setClicked(false);

    if (status) {
      setSnackbarMessage("Workshops banking details have been rejected!, Workshop has been updated of the review  , you may close this tab.");
      setShowSnackbar(true);
      return;
    }
    setSnackbarMessage("Workshops banking details have been updated!, Workshop has been updated of the review  , you may close this tab.");
    setShowSnackbar(true);
  }


   
  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };


  return (
    <IonPage>
      <IonContent fullscreen>
        <Header />
        <div className="container">
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
              <div style={{ padding: "50px" }}>
                <Lottie options={defaultOptions} />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 ">
              <h5>Workshop has requested to update their banking details, please confirm below.</h5>
              
              <div>
                  <label className="form-label fw-bolder">Workshop Info</label>
                <div >{workshopName}</div>
                <div >{workshopPhone}</div>
                <div >{workshopEmail}</div>
                </div>
                
               
                <div className="spacer"></div>
                <div>
                  <label className="form-label fw-bolder">Bank</label>
                   <div >{bank}</div> 
                </div>
                
               
                <div className="spacer"></div>
                <div>
                  <label className="form-label fw-bolder">Bank Account Number</label>
                  <div >{bankingDetails.bank_account_no}</div>
                </div>
               
                <div className="spacer"></div>

                <div>
                  <label className="form-label fw-bolder">Bank Account Type</label>
                 <div >{bankAccountType}</div>
                </div>
              
                <div className="spacer"></div>

                <div>
                  <label className="form-label fw-bolder">Bank Branch Code</label>
                  <div >{bankingDetails.bank_branch_code}</div>
                </div>
               

                <div className="spacer"></div>

                <div className="row">
                  <div className="col">
                    <button
                     onClick={() => sendUpdateRequest(false)}

                      className="btn btn-warning btn-lg w-100"
                  
                    >
                      Accept{" "}
                      {clicked ? (
                          <IonSpinner name="crescent"></IonSpinner>
                        ) : null}
                    </button>
                    <div className="spacer"></div>
                    <button
                     onClick={() => sendUpdateRequest(true)}

                     className="btn btn-warning btn-lg w-100"
 
                   >
                    Reject{" "}
                     {clicked ? (
                         <IonSpinner name="crescent"></IonSpinner>
                       ) : null}
                   </button>
                  </div>
                </div>
           
            </div>
          </div>
        </div>
        {showSnackbar && (
          <CustomSnackbar message={snackbarMessage} onClose={handleCloseSnackbar} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default UpdateBankingDetails;
