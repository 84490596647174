import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getRmi } from "../services/rmi.service";
import { Link } from "react-router-dom";
import { resetPassword, signin } from "../services/auth.service";
import { useLocation } from "react-router-dom";

const ResetForm: React.FC = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const origin = `${window.location.origin}/register`
  console.log(token)
   window.localStorage.setItem(
          "Authorization",
          String(token)
        )

  const history = useHistory();
  const [clicked, setClicked] = useState(false);
  const [user, setUser] = useState({
    password: "",
    confirmpassword: "",
  });
  
  const [mainError, setMainError] = useState(null);
//   const emailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  const [passError, setPassError] = useState(false);
  const [message, setMessage]:any = useState(null);

  useEffect(() => {}, []);

  const resetRequest = () => {
    setClicked(true);
    
    resetPassword({"password":user.password})
      .then((resetPasswordResponse: any) => {
        setMessage("Password reset successfully.");
        setClicked(false);
        setMessage(resetPasswordResponse.msg);
      })
      .catch((error: any) => {
        setClicked(false);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <h5>Enter your new password below.</h5>
          <form>
            <div className="form-group">
              <label className="form-label fw-bolder">Password</label>
              <input
                className="form-control"
                type="password"
                defaultValue={user.password}
                onChange={(ev: any) => {
                  user.password = ev.target.value;
                  setUser(user);
                  if (user.password !== user.confirmpassword) {
                    setPassError(true);
                  } else {
                    setPassError(false);
                  }
                  console.log(user.password, user.confirmpassword);
                }}
              />
            </div>
            <p className="text-danger">
              {passError ? <small>Your passwords do not match.</small> : null}
            </p>
            <div className="form-group">
              <label className="form-label fw-bolder">Confirm Password</label>
              <input
                className="form-control"
                type="password"
                defaultValue={user.confirmpassword}
                onChange={(ev: any) => {
                  user.confirmpassword = ev.target.value;
                  setUser(user);
                  if (user.password !== user.confirmpassword) {
                    setPassError(true);
                  } else {
                    setPassError(false);
                  }
                  console.log(user.password, user.confirmpassword);
                }}
              />
            </div>
            <p className="text-danger">
              {passError ? <small>Your passwords do not match.</small> : null}
            </p>
          </form>
          <div className="spacer"></div>
          {mainError ? <p className="text-danger">{mainError}</p> : null}
          {message ? <p className="text-success">{message}</p> : null}
          <div className="row">
            <div className="col">
              <button
                disabled={!user.confirmpassword || !user.password || clicked}
                onClick={() => resetRequest()}
                className="btn btn-warning btn-lg w-100"
                type="button"
              >
                Reset Password
              </button>
            </div>
          </div>
          <div className="spacer"></div>
          <p className="card-text m-0">
            Don&#39;t have an account? 
            <a target="_blank" href={origin}>
              Register here
            </a>
            <br />
          </p>
          <div className="spacer"></div>
        </div>
      </div>
    </>
  );
};

export default ResetForm;
