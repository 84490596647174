
export class BusinessModel {
    phone_number: any;
    email: any;
    address: any;
    latlng: any;
    place_id: any;
    reg_no: any;
    vat_no: any;
    bank: any;
    bank_account_no: any;
    bank_account_type: any;
    bank_branch_code: any;
    workshop_name: any;
  
    constructor(obj: any) {
      this.phone_number = obj.phone_number;
      this.email = obj.email;
      this.address = obj.address;
      this.latlng = obj.latlng;
      this.place_id = obj.place_id;
      this.reg_no = obj.reg_no;
      this.vat_no = obj.vat_no;
      this.bank = obj.bank;
      this.bank_account_no = obj.bank_account_no;
      this.bank_account_type = obj.bank_account_type;
      this.bank_branch_code = obj.bank_branch_code;
      this.workshop_name = obj.workshop_name;
    }
}
  
export class WorkshopModel {
    vehicles: any[];
    oldcars: any;
    newcars: any;
    yeslift: any;
    nolift: any;
    distance!: any;
    rate: any;
    slots_per_hour: any;
    rate_discount: any;
    parts_discount: any;
    workshop_hours: any;
    saturday: boolean;
    sunday: boolean;
    weekdays: boolean;
    weekdays_start: any;
    weekdays_end: any;
    sat_start: any;
    sat_end: any;
    sun_start: any;
    sun_end: any;
  
    constructor(obj:any) {
      this.vehicles = obj.vehicles ? obj.vehicles : [];
      this.oldcars = obj.oldcars;
      this.newcars = obj.newcars;
      this.yeslift = obj.yeslift;
      this.nolift = obj.nolift;
      this.distance = obj.distance;
      this.rate = obj.rate;
      this.slots_per_hour = obj.slots_per_hour;
      this.rate_discount = obj.rate_discount;
      this.parts_discount = obj.parts_discount;
      this.workshop_hours = obj.workshop_hours;
      this.saturday = obj.saturday;
      this.sunday = obj.sunday;
      this.weekdays = obj.weekdays;
      this.weekdays_start = obj.weekdays_start;
      this.weekdays_end = obj.weekdays_end;
      this.sat_start = obj.sat_start;
      this.sat_end = obj.sat_end;
      this.sun_start = obj.sun_start;
      this.sun_end = obj.sun_end;
    }
  }