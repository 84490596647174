import {
  IonCheckbox,
  IonChip,
  IonContent, 
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../components/header/Header";
import StartModal from "../../components/modals/StartModal";
import "./WorkshopConfirm.scss";

import LandingImage from "../../assets/img/Workshop Left_.png";
import PlaceImage from "../../assets/img/dash-icon-placeholder.svg";
import CheckMark from "../../assets/img/icon-checkmark.svg";
import Business from "../../assets/img/dash-icon-business.svg";
import EditIcon from "../../assets/img/dash-icon-edit.svg";
import WorkshopIcon from "../../assets/img/icon-workshop.svg";
import SettingsIcon from "../../assets/img/dash-icon-settings.svg";

import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import {
  businessSub,
  updateBusiness,
  updateWorkshop,
  workshopSub,
} from "../../services/data.service";
import { BusinessModel, WorkshopModel } from "../../models/business.class";
import { saveOnboarding } from "../../services/workshop.service";
import Register from "../Register/Register";
import { RegisterModel } from "../../models/RegisterModel";
import { signup } from "../../services/auth.service";

const WorkshopConfirm: React.FC = ({match}: any) => {
  const history = useHistory();
  const [bankname, setBankName] = useState("");
  const [bankaccounttype, setBankAccountType] = useState("");
  const [loading, setLoading] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(false);

  const [currentStep, setStep] = useState(Number(match.params.id));

  const origin = window.location.origin;

  // const [registerDetails, setBusiness] = useState(new BusinessModel({}));
  // const [registerDetails, setWorkshop] = useState(new WorkshopModel({}));
  // const [rmi, setRmi] = useState({
  //   location: "",
  //   mem_no: "",
  //   name: "",
  //   phone_number: "",
  // });
  const [registerDetails,setRegisterDetails] = useState(new RegisterModel({}));

  const submitWorkshop = () => {
    setClicked(true);
    registerDetails.url = origin;
    signup(registerDetails)
      .then((onboardingResponse: any) => {
     
        setClicked(false);
        history.push("/onboarded");
      })
      .catch((e: any) => {
        setError(true)
        console.log(e);
        setClicked(false);
      });
    };

  useEffect(() => {
    // @ts-ignore

    const registerDetails = JSON.parse(window.localStorage.getItem("userDetails"));
    if (registerDetails) {
      setRegisterDetails(registerDetails);
    }
    setBankAccountType(registerDetails.bank_account_type.name)
    setBankName(registerDetails.bank.name)

    // const businessDetails = JSON.parse(window.localStorage.getItem("business"));
    // if (businessDetails) {
    //   setBusiness(businessDetails);
    //   updateBusiness(businessDetails);
    // }
    // @ts-ignore
    // const rmiDetails = JSON.parse(window.localStorage.getItem("rmiDetails"));
    // if (rmiDetails) {
    //   setRmi(rmiDetails);
    // }
    // @ts-ignore
    // const workShopConfig = JSON.parse(window.localStorage.getItem("workshop"));
    // if (workShopConfig) {
    //   setWorkshop(workShopConfig);
    //   updateWorkshop(workShopConfig);
    // }
    // businessSub.subscribe((business: BusinessModel) => {
    //   console.log(business);
    //   if (business.bank) {
    //     setBusiness(business);
    //   }
    // });
    // workshopSub.subscribe((workshop: WorkshopModel) => {
    //   console.log(workshop);
    //   if (workshop.vehicles) {
    //     if (workshop.vehicles.length > 0) {
    //       setWorkshop(workshop);
    //     }
    //   }
    // });
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  }, []);

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header />
         
           
              
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
                      <h3 className="fw-light headline-text">
                        <img className="smiley-face" src={CheckMark} />
                        Confirm your details
                      </h3>
                      <img className="left-image" src={LandingImage} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5  right-scrollable">
                      <div>
                      <button
                        className="btn btn-outline-warning btn-lg mb-3"
                        type="button"
                        onClick={() => {
                          // updateWorkshop(registerDetails);
                          setStep(2);
                          // scrollToTop();
                          // history.push("/onboarding/2");
                          window.location.href = "/onboarding/2";
                        }}

                      >
                        Back
                        </button>

                      </div>
                      <div className="card mb-3 p-3">
                        <div className="edit-btn">
                          {/* <img src={EditIcon} /> */}
                    
                        </div>
                        <div className="card-body p-0 d-flex align-items-start">
                          <img src={PlaceImage} className="inline-abs-image" />
                          <div>
                            {/* <h6 className="fw-bolder m-0">
                              <small>{rmi.name}</small>
                            </h6> */}
                            <p className="m-0">
                              <small>
                                <strong>RMI No:</strong>
                                {registerDetails.rmi}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <div
                          className="edit-btn"
                          onClick={() => {
                            // updateBusiness(business);
                            // updateWorkshop(workshop);
                            history.push("/register");
                          }}
                        >
                          {/* <img src={EditIcon} /> */}
                        </div>
                        <div className="card-body p-0 d-flex align-items-start">
                          <img src={Business} className="inline-abs-image" />
                          <div>
                            <h6 className="fw-bolder m-0">
                              <small>Business Details</small>
                            </h6>
                            <div className="spacer"></div>
                            <p className="m-0">
                            <small>
                                <strong>Workshop Name:</strong> {registerDetails.workshop_name}
                              </small>
                              </p>
                              <p className="m-0">
                              <small>
                                <strong>Tel:</strong> {registerDetails.phone_number}
                              </small>
                            </p>
                            <p className="m-0">
                              <small>
                                <strong>Email:</strong> {registerDetails.email}
                              </small>
                            </p>
                            <hr />
                            <p className="m-0">
                              <strong>Physical Address</strong>
                            </p>
                            <p className="m-0">
                              <small>{registerDetails.address}</small>
                            </p>
                            <hr />
                            <p className="m-0">
                              <small>
                                <strong>Company Reg:</strong> {registerDetails.reg_no}
                              </small>
                            </p>
                            <p className="m-0">
                              <small>
                                <strong>VAT No:</strong> {registerDetails.vat_no}
                              </small>
                            </p>
                            <hr />
                            <p className="m-0">
                              <strong>Banking Details</strong>
                            </p>
                            <p className="m-0 d-flex justify-content-between">
                              <small>Bank</small>
                              <small>{bankname}</small>
                            </p> 
                             <p className="m-0 d-flex justify-content-between">
                              <small>Account Type</small>
                              <small>{bankaccounttype}</small>
                            </p> 
                            <p className="m-0 d-flex justify-content-between">
                              <small>Account Number</small>
                              <small>{registerDetails.bank_account_no}</small>
                            </p>
                            <p className="m-0 d-flex justify-content-between">
                              <small>Branch Code</small>
                              <small>{registerDetails.bank_branch_code}</small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <div
                          className="edit-btn"
                          onClick={() => {
                            // updateBusiness(registerDetails);
                            // updateWorkshop(workshop);
                            history.push("/onboarding/1");
                          }}
                        >
                          {/* <img src={EditIcon} /> */}
                        </div>
                        <div className="card-body p-0 d-flex align-items-start">
                          <img
                            src={SettingsIcon}
                            className="inline-abs-image"
                          />
                          <div>
                            <h6 className="fw-bolder m-0">
                              <small>Operating Conditions</small>
                            </h6>
                            <div className="spacer"></div>
                            <p className="m-0 d-flex justify-content-between">
                              <small>Labour rate (p/hr) </small>
                              <small>R {registerDetails.rate}</small>
                            </p>
                            <p className="m-0 d-flex justify-content-between">
                              <small>Labour Discount</small>
                              <small>{registerDetails.rate_discount}%</small>
                            </p>
                            <p className="m-0 d-flex justify-content-between">
                              <small>Parts Discount</small>
                              <small>{registerDetails.parts_discount}%</small>
                            </p>
                            {/* <p className="m-0 d-flex justify-content-between">
                              <small>Allocated Hrs per day</small>
                              <small>{registerDetails.workshop_hours}</small>
                            </p>
                            <p className="m-0 d-flex justify-content-between">
                              <small>Service Bays </small>
                              <small>{registerDetails.slots_per_hour}</small>
                            </p> */}
                            {/* <hr /> */}
                            {/* <p className="m-0">
                              <strong>Operating Hours</strong>
                            </p> */}
                            {/* {registerDetails.weekdays ? (
                              <p className="m-0 d-flex justify-content-between">
                                <small>Mon - Fri</small>
                                <small>
                                  {registerDetails.weekdays_start}am -{" "}
                                  {registerDetails.weekdays_end}pm
                                </small>
                              </p>
                            ) : null} */}
                            {/* {registerDetails.saturday ? (
                              <p className="m-0 d-flex justify-content-between">
                                <small>Saturday</small>
                                <small>
                                  {registerDetails.sat_start}am - {registerDetails.sat_end}pm
                                </small>
                              </p>
                            ) : null}
                            {registerDetails.sunday ? (
                              <p className="m-0 d-flex justify-content-between">
                                <small>Sunday</small>
                                <small>
                                  {registerDetails.sun_start}am - {registerDetails.sun_end}pm
                                </small>
                              </p>
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <div
                          className="edit-btn"
                          onClick={() => {
                            // updateBusiness(registerDetails);
                            // updateWorkshop(registerDetails);
                            history.push("/onboarding/1");
                          }}
                        >
                          {/* <img src={EditIcon} /> */}
                        </div>
                        <div className="card-body p-0 d-flex align-items-start">
                          <img
                            src={WorkshopIcon}
                            className="inline-abs-image"
                          />
                          <div>
                            <h6 className="fw-bolder m-0">
                              <small>Workshop</small>
                            </h6>
                            <div className="spacer"></div>
                            <p className="m-0">
                              <strong>
                                Vehicles your workshop is qualified to service
                              </strong>
                            </p>
                            {registerDetails.vehicles.map((veh: any) => {
                              return <IonChip mode="md">{veh.name}</IonChip>;
                            })}
                             <hr />
                            {/* <p className="m-0">
                              <strong>Ages of Vehicles you Service</strong>
                            </p>  */}
                            {/* {registerDetails.newcars ? (
                              <IonChip mode="md">
                                <small>0 - 10 Years</small>
                              </IonChip>
                            ) : null} */}
                            {/* {registerDetails.oldcars ? (
                              <IonChip mode="md">
                                <small>10+ Years</small>
                              </IonChip>
                            ) : null} */}
                            {/* <hr />
                            <p className="m-0">
                              <strong>Complimentary Lifts Offered</strong>
                            </p> */}
                            {/* <p className="m-0"> */}
                              {/* <small>
                                {registerDetails.yeslift
                                  ? "Yes -" + registerDetails.distance + "km"
                                  : "No"}
                              </small> */}
                            {/* </p> */}
                          </div>
                        </div>
                      </div>
                      <div >
                      <div>
                      <button
                      style={{width: '100%'}}
                        className="btn btn-outline-warning btn-lg mb-3"
                        type="button"
                        onClick={() => {
                          // updateWorkshop(registerDetails);
                          setStep(2);
                          // scrollToTop();
                          // history.push("/onboarding/2");
                          window.location.href = "/onboarding/2";
                        }}

                      >
                        Back
                        </button>
                      </div>
                      <button
                        className="btn btn-warning btn-lg w-100"
                        type="button"
                        onClick={() => {
                          submitWorkshop();
                        }}
                      >
                        Save
                        {clicked ? (
                          <IonSpinner name="crescent"></IonSpinner>
                        ) : null}
                      </button>

{error && (
            <div className="alert alert-danger">
              Something went wrong, please try again later.
            </div>
          )}
                      </div>
                    </div>
                  </div>
                </div>
              
         
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default WorkshopConfirm;
