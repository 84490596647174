export class RegisterModel {
    name: string;
    surname: string;
    phone_number: string;
    email: string;
    password: string;
    confirmpassword: string;
    rmi: string;
    workshop_name: any;
    bank_account_no: any;
    bank_account_type: any;
    bank_branch_code: any;
    bank: any;
    reg_no: any;
    vat_no: any;
    vehicles: any[];
    rate: any;
    rate_discount: any;
    parts_discount: any;
    address: any;
    latlng: any;
    place_id: any;
    province: any;
    new_cars: any;
    old_cars: any;
    url: any;

    // Weekday working hours
    isWeekdayWorking: boolean;
    weekdayStartTime: string;
    weekdayEndTime: string;

    // Saturday working hours
    isSaturdayWorking: boolean;
    saturdayStartTime: string;
    saturdayEndTime: string;

    // Sunday working hours
    isSundayWorking: boolean;
    sundayStartTime: string;
    sundayEndTime: string;
    initial_hours: any;
  
    constructor(obj: any) {
      this.name = obj.name;
      this.surname = obj.surname;
      this.phone_number = obj.phone_number;
      this.email = obj.email;
      this.password = obj.password;
      this.confirmpassword = obj.confirmpassword;
      this.rmi = obj.rmi
      this.workshop_name = obj.workshop_name;
      this.bank_account_no = obj.bank_account_no;
      this.bank_account_type = obj.bank_account_type;
      this.bank_branch_code = obj.bank_branch_code;
      this.bank = obj.bank;
      this.reg_no = obj.reg_no;
      this.vat_no = obj.vat_no;
      this.vehicles = obj.vehicles ? obj.vehicles : [];
      this.rate = obj.rate;
      this.rate_discount = obj.rate_discount;
      this.parts_discount = obj.parts_discount;
      this.address = obj.address;
      this.latlng = obj.latlng;
      this.place_id = obj.place_id;
      this.province = obj.province;
      this.new_cars = obj.new_cars;
      this.old_cars = obj.old_cars;
      this.url = obj.url;
      this.weekdayStartTime = obj.weekdayStartTime;
      this.weekdayEndTime = obj.weekdayEndTime;
      this.saturdayStartTime = obj.saturdayStartTime;
      this.saturdayEndTime = obj.saturdayEndTime;
      this.sundayStartTime = obj.sundayStartTime;
      this.sundayEndTime = obj.sundayEndTime;
      this.isWeekdayWorking = obj.isWeekdayWorking;
      this.isSaturdayWorking = obj.isSaturdayWorking;
      this.isSundayWorking = obj.isSundayWorking;
      this.initial_hours = obj.initial_hours
  
    }
  }
  

  // RMI = None
  // Created_Date = None
  // Updated_dated = None

  