import "./LeftMenu.scss";
import Stars from "../../assets/img/5Stars_Blue@1x.svg";
import { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
// import { getUser } from "../../services/user.service";

interface UserData {
  active: boolean;
  email: string;
  email_confirmation: boolean;
  name: string;
  phone_number: string;
  role: string;
  surname: string;
}

const LeftMenu: React.FC<any> = () => {
  const history = useHistory();



  const [userInfo, setUser]:any = useState();
  const [userError, setUserError]: any = useState(null);
  const [isAdmin, setIsAdmin]: any = useState(false);
  const [isWorkshop, setIsWorkshop]: any = useState(false);

  const signout = () => {
    history.replace("/");
  };


  useEffect(() => {

      

    if (String(window.localStorage.getItem("user")) === "admin") {
      setIsAdmin(true)
    }
    else if (String(window.localStorage.getItem("user")) === "workshop") {
      setIsAdmin(false)
      setIsWorkshop(true)
    }
    else{
      setIsAdmin(false)
    }
   
    console.log(window.localStorage.getItem("user"), "user")
      console.log(isAdmin)
      console.log(isWorkshop)
  }, []);

  return (
    <>
      <div className="left-sidebar">
        {/* {userInfo ? ( */}
          <>
            {/* {workshopInfo ? (
              <h6>{workshopInfo.rmidetails.name}</h6>
            ) : (
              <IonProgressBar
                color="light"
                type="indeterminate"
              ></IonProgressBar>
            )} */}
            <div className="d-flex justify-content-between small">
              <img src={Stars} />
              {/* <span className="small">(35)</span> */}
            </div>
        {isAdmin ? ( 
              <div className="left-menu-container">
                <h6 className="menu-container-heading">Admin</h6>
                {/* <NavLink
                  activeClassName="active"
                  replace
                  className="menu-item-center"
                  to="/manage-users"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-people"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                    ></path>
                  </svg>
                  <span>Manage Users</span>
                </NavLink> */}
                <NavLink
                  activeClassName="active"
                  replace
                  className="menu-item-center"
                  to="/manage-workshops"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-building"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    ></path>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                  </svg>
                  <span>Manage Workshops</span>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  replace
                  className="menu-item-center"
                  to="/manage-bookings"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-building"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    ></path>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                  </svg>
                  <span>Manage Bookings</span>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  replace
                  className="menu-item-center"
                  to="/manage-payments"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-building"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    ></path>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                  </svg>
                  <span>Manage Payments</span>
                </NavLink>
                {/* <NavLink
                  activeClassName="active"
                  replace
                  className="menu-item-center"
                  to="/manage-invoices"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-file-ruled"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v4h10V2a1 1 0 0 0-1-1H4zm9 6H6v2h7V7zm0 3H6v2h7v-2zm0 3H6v2h6a1 1 0 0 0 1-1v-1zm-8 2v-2H3v1a1 1 0 0 0 1 1h1zm-2-3h2v-2H3v2zm0-3h2V7H3v2z"
                    ></path>
                  </svg>
                  <span>Manage Invoices</span>
                </NavLink> */}
              </div>
           ) : 
           <div className="left-menu-container">
           <NavLink
          activeClassName="active"
          replace
          className="menu-item-center"
          to="/bookings"
        >
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              d="M8,0 C9.36170213,0 10.7477204,0.0972644377 11.9635258,0.267477204 C12.5957447,0.364741641 13.1306991,0.924012158 13.2036474,1.04559271 L14.1762918,3.8449848 L15.6352584,4.06382979 C15.8054711,4.0881459 16,4.23404255 16,4.40425532 L16,4.76899696 C16,4.93920973 15.8541033,5.08510638 15.6838906,5.08510638 L14.8328267,5.08510638 C15.0030395,5.23100304 15.7082067,5.71732523 15.7325228,7.39513678 L15.7325228,12.6018237 L15.7325228,12.6018237 C15.7325228,12.8693009 15.5379939,13.0881459 15.2705167,13.0881459 L13.1793313,13.0881459 C12.9361702,13.0881459 12.7173252,12.8693009 12.7173252,12.6018237 L12.7173252,11.0668693 L12.7173252,11.0668693 C11.1367781,11.1641337 9.58054711,11.212766 8,11.1884498 C6.41945289,11.212766 4.83890578,11.1641337 3.28267477,11.0668693 L3.28267477,12.6018237 L3.28267477,12.6018237 C3.28267477,12.8693009 3.06382979,13.0881459 2.82066869,13.0881459 L0.729483283,13.0881459 C0.462006079,13.0881459 0.243161094,12.8693009 0.243161094,12.6018237 C0.243161094,12.0243161 0.247382641,11.2689759 0.252307779,10.4822378 L0.258330519,9.53245314 C0.263255657,8.74402651 0.267477204,7.98277609 0.267477204,7.39513678 C0.291793313,5.71732523 0.996960486,5.23100304 1.14285714,5.08510638 L0.291793313,5.08510638 C0.145896657,5.08510638 0,4.93920973 0,4.76899696 L0,4.40425532 C0,4.23404255 0.194528875,4.0881459 0.364741641,4.06382979 L1.82370821,3.8449848 L2.79635258,1.04559271 C2.86930091,0.924012158 3.37993921,0.364741641 4.01215805,0.267477204 C5.25227964,0.0972644377 6.61398176,0 8,0 Z M5,7 L2,7 L2,9 L5,9 L5,7 Z M14,7 L11,7 L11,9 L14,9 L14,7 Z"
              id="Combined-Shape"
            ></path> 
          </svg>

          <span>Bookings</span>
        </NavLink>
             {/* <NavLink
               activeClassName="active"
               replace
               className="menu-item-center"
               to="/diary"
             >
               <svg
                 width="16px"
                 height="16px"
                 viewBox="0 0 16 16"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor"
               >
                 <path
                   d="M5,0 L5,2 L11,2 L11,0 L13,0 L13,2 L16,2 L16,16 L0,16 L0,2 L3,2 L3,0 L5,0 Z M9,10 L7,10 L7,12 L9,12 L9,10 Z M5,10 L3,10 L3,12 L5,12 L5,10 Z M13,10 L11,10 L11,12 L13,12 L13,10 Z M5,6 L3,6 L3,8 L5,8 L5,6 Z M13,6 L11,6 L11,8 L13,8 L13,6 Z M9,6 L7,6 L7,8 L9,8 L9,6 Z"
                   id="Combined-Shape"
                 ></path>
               </svg>
               <span>Diary</span>
             </NavLink> */}
{/*              
             <NavLink
               activeClassName="active"
               replace
               className="menu-item-center"
               to="/invoices"
             >
               <svg
                 width="16px"
                 height="16px"
                 viewBox="0 0 16 16"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor"
               >
                 <path
                   d="M13,0 C14.6568542,-3.04359188e-16 16,1.34314575 16,3 L16,13 C16,14.6568542 14.6568542,16 13,16 L3,16 C1.34314575,16 2.02906125e-16,14.6568542 0,13 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L13,0 Z M13,9 L3,9 L3,11 L13,11 L13,9 Z M13,5 L3,5 L3,7 L13,7 L13,5 Z"
                   id="Combined-Shape"
                 ></path>
               </svg>
               <span>Upload Invoices</span>
             </NavLink>
             <NavLink
               activeClassName="active"
               replace
               className="menu-item-center"
               to="/workshop"
             >
               <svg
                 width="16px"
                 height="16px"
                 viewBox="0 0 16 16"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor"
               >
                 <path
                   d="M11.0011006,0.545590002 C13.9323479,1.658448 16,4.35285243 16,7.5 C16,10.9943761 13.4509254,13.9305945 10.0009562,14.7634864 L10,16 L6,16 L6.00004783,14.7637288 C2.54956914,13.9311641 0,10.9947151 0,7.5 C0,4.35249362 2.06812357,1.65783362 4.99990199,0.545209426 L5,8 L11,8 L11.0011006,0.545590002 Z"
                   id="Combined-Shape"
                 ></path>
               </svg>
               <span>Workshop</span>
             </NavLink>
             
           */}

             <NavLink
               activeClassName="active"
               replace
               className="menu-item-center"
               to="/business"
             >
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="1em"
                 height="1em"
                 viewBox="0 0 16 16"
                 fill="currentColor"
                 className="bi bi-briefcase-fill"
               >
                 <path
                   fillRule="evenodd"
                   d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"
                 ></path>
                 <path
                   fillRule="evenodd"
                   d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v1.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 5.884V4.5zm5-2A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"
                 ></path>
               </svg>
               <span>Business</span>
             </NavLink> 
             {/* <NavLink
               activeClassName="active"
               replace
               className="menu-item-center"
               to="/profile"
             >
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="1em"
                 height="1em"
                 viewBox="0 0 16 16"
                 fill="currentColor"
                 className="bi bi-person-fill"
               >
                 <path
                   fillRule="evenodd"
                   d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                 ></path>
               </svg>
               <span>Profile</span>
             </NavLink> */}
             {isWorkshop &&   
             <NavLink
               activeClassName="active"
               replace
               className="menu-item-center"
               to="/add-user"
             >
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="1em"
                 height="1em"
                 viewBox="0 0 16 16"
                 fill="currentColor"
                 className="bi bi-person-fill"
               >
                 <path
                   fillRule="evenodd"
                   d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                 ></path>
               </svg>
               <span>Users</span>
             </NavLink>
           } 
           </div>
           
           }
         
        
            <div className="left-menu-container">
             <NavLink
                activeClassName="active"
                replace
                className="menu-item-center"
                to="/settings"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="bi bi-gear-fill"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"
                  ></path>
                </svg>
                <span>Settings</span>
              </NavLink>
              <a
                className="menu-item-center"
                onClick={() => {
                  signout();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="bi bi-box-arrow-in-left"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                  ></path>
                </svg>
                <span>Sign Out</span>
              </a>
            </div>
          </>
        {/* ) : ( */}
          {/* <>
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
            <br />
          </> */}
        {/* )} */}
      </div>
    </>
  );
};

export default LeftMenu;
