import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import "./Users.scss";
import { useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { updateUser } from "../../../services/user.service";
import { addUser } from "../../../services/user.service";


const Users: React.FC = () => {
  const [email, setEmail]: any = useState();
  const [clicked, setClicked]: any = useState(false);
  const [success, setSuccess]: any = useState(false);
  const [role, setRole]: any = useState("basic");
  const [error, setError]: any = useState(false);
  const [message, setMessage]: any = useState("");

  const sendEmailRequest = () => {
    setClicked(true);
    console.log(email, role, window.localStorage.getItem("workshop_id"));
    addUser({ email: String(email), role: String(role), workshop_id: String(window.localStorage.getItem("workshop_id")) , url: window.location.origin}).then((response: any) => {
      setClicked(false);
      setSuccess(true);
    
    }).catch((error: any) => {
      setMessage(error.msg);
      setClicked(false);
      console.log(JSON.stringify(error))
      console.log(error)
      setError(true);

    })
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setRole("workshop");
    } else {
      setRole("basic");
    }
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-person-fill"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    ></path>
                  </svg>
                  <span>Users</span>
                </h6>
              </div>
              <div className="content-container grey-bg">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="card mb-3">
                      <div className="card-body p-0">
                        <h6 className="card-title fw-bolder mb-3">
                          Below you can add additional users to the workshop
                        </h6>
                        <div className="spacer"></div>
                        <form>
                          <div className="form-group">
                            <label className="form-label fw-bolder">
                              Email
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              onChange={(ev) => {
                                console.log(ev.target.value);
                                const email = ev.target.value;
                                setEmail(email);
                              }}
                            />
                          </div>
                          <div className="form-group">
                       <div style={{paddingTop:"20px"}}></div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="roleCheckbox"
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="roleCheckbox"
                              >
                                Is this user an admin of the workshop?
                              </label>
                            </div>
                          </div>
                          <div style={{paddingTop:"20px"}}></div>
                          <button
                            disabled={clicked}
                            className="btn btn-warning btn-lg w-100"
                            type="button"
                            onClick={() => {
                              sendEmailRequest();
                            }}
                          >
                            Save
                            {clicked ? (
                              <IonSpinner name="crescent"></IonSpinner>
                            ) : null}
                          </button>
                       
<p className="mt-3">
  A registration email will be sent to the user.
</p>
                        </form>
                        {success && (
                          <div className="alert alert-success mt-3">
                            Profile update email has been sent to new user.
                          </div>
                        )}
                          {error && (
                           <div className="alert alert-danger mt-3">
                            {message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Users;
