import { IonActionSheet, IonButton, IonContent, IonModal, IonPage } from "@ionic/react";
import StartModal from "../../../components/modals/StartModal";
import "./ManageWorkshops.scss";
import { useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import { workshop as activeItem } from "../../../services/data.service";
import { getWorkshopById } from "../../../services/admin.service";

import {
  Grid,
  GridColumn as Column,
  GridCellProps,
} from "@progress/kendo-react-grid";
import {
  getAdminWorkshops,
  // updateAdminWorkshop,
  deactivateWorkshop,
  activateWorkshop
} from "../../../services/admin.service";
import { ConnectableObservable } from "rxjs";
import ViewWorkshopModal from "../../../components/modals/ViewWorkshopModal";


const ManageWorkshops: React.FC = () => {
  const [workshops, setWorkshops]:any = useState();
  const [workshopInfo, setWorkshopInfo]: any = useState([]);
  const [showModal, setShowModal]: any = useState(false);
  const [showActionSheet, setShowActionSheet]: any = useState(false);
  const [workshopDetails, setWorkshopDetails]: any = useState(null);
  const [activeitem, setActiveitem]: any = useState(null);
  const [modaldata, setModaldata]: any = useState({});

  // generateInvoice
  // updateInvoice

  useEffect(() => {
    activeItem.subscribe((workShopDetails: any) => {
      console.log(workShopDetails);
      if (workShopDetails) {
        setWorkshopInfo(workShopDetails);
      }
      getAllWorkshops();
    });
  }, []);

  const getAllWorkshops = () => {
    getAdminWorkshops({}).then((adminDetails: any) => {
      setWorkshops(adminDetails);
    });
   
  };

  const showAction = (data: any) => {
    setActiveitem(data);
    setShowActionSheet(true);
  };

  const enableWorkshop = (data: any) => {
    activateWorkshop({id:data}).then((response: any) => {
      console.log(response, "response")
      getAllWorkshops()
    });

  };

  const disableWorkshop = (data: any) => {
    deactivateWorkshop({id:data}).then((response: any) => {
      console.log(response, "response")
      getAllWorkshops()
    })
  }


  useEffect(() => {}, []);

  const BooleanCell = (props: GridCellProps) => {
    return (
      <td>
        {props.dataItem[props.field || 0]
          ? <div style={{background:"green", textAlign:"center", color:"white", width:"100%", height:"100%"}}>Enabled</div>
          : <div style={{background:"red" , textAlign:"center", color:"white", width:"100%", height:"100%" }}>Disabled</div>}
      </td>
    );
  };

  
  const ShowModal = async (data: any) => {
    setShowModal(true);
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-building"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    ></path>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                  </svg>
                  <span>Manage Workshops</span>
                </h6>
              </div>
              <div className="content-container">

               <IonActionSheet
                  isOpen={showActionSheet}
                  onDidDismiss={() => setShowActionSheet(false)}
                  mode="md"
                  cssClass="my-custom-class"
                  buttons={[
                    {
                      text: "Enable",
                      handler: () => {
                        if (activeitem.status !== "approved") {
                          activeitem.status = "approved";
                          console.log(activeitem._id, "activeiddtem")
                          setActiveitem(activeitem);
                          enableWorkshop(String(activeitem._id));
                        }
                        console.log("Enable clicked");
                      },
                    },
                    {
                      text: "Disable",
                      handler: () => {
                        if (activeitem.status !== "rejected") {
                          activeitem.status = "rejected";
                          setActiveitem(activeitem);
                          console.log(activeitem._id, "activeiddtem")
                          disableWorkshop(String(activeitem._id));
                        }
                      }, 
                    },
                    {
                      text: "Cancel",
                      role: "cancel",
                      handler: () => {
                        setActiveitem(null);
                      },
                    },
                  ]}
                ></IonActionSheet> 
                {/* {workshops ? ( */}
                  <Grid
                    style={{ height: "100%" }}
                    data={workshops}
                    sortable={true}
                    onRowClick={(e: any) => {
                     setWorkshopDetails(e.dataItem)
                      
                      ShowModal(e.dataItem);

                    }}
                  >
                  <Column field="workshop_name" title="Name" />
                  <Column field="RMI" title="RMI" />
                  <Column field="email" title="Email" />
                  <Column field="phone_number" title="Phone" />
                  {/* <Column field="address" title="Address" /> */}
                  <Column
                    field="address"
                    title="Address"
                    cell={(props: GridCellProps) => (
                      <td style={{ textAlign: "right" }}>
                        <span className="service-status-cell">
                          {props.dataItem.address}
                        </span>
                      </td>
                    )}
                  />
                  <Column field="province.name" title="Province" />
                  <Column field="active" title="Status" cell={BooleanCell}/>

                  
                  <Column
                    title="Options"
                    cell={(props: GridCellProps) => (
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            showAction(props.dataItem);
                          }}
                        >
                          Review
                        </button>
                      </td>
                    )}
                    />
                  </Grid>
                {/* ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {showModal ? (
              <ViewWorkshopModal
                data={workshopDetails}
                onClose={() => setShowModal(false)}
              />
            ) : null}
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default ManageWorkshops;
