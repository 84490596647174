import { IonButton, IonContent, IonPage } from "@ionic/react";
import Header from "../../components/header/Header";
import "./Onboarded.scss";

import Cog from "../../assets/img/dash-icon-settings-white.svg";

import { useHistory } from "react-router";
import { useEffect, useState } from "react";

const Onboarded: React.FC = () => {
  const history = useHistory();
  const [status, setStatus] = useState({
    email: "",
    status: "",
  });

  const [email, setEmail] = useState('')

  useEffect(() => {

    setEmail(String(window.localStorage.getItem("Email")))
  }, [])

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header />
          <div className="onboarded-container">
            <img src={Cog} className="settings-cog" />
            <div className="text-center">
              <h3>
                Thank you for your interest in joining the Servisor Network. Your request has been submitted for approval.
              </h3>
              <h3>
                Additional documentation is required before we can activate your account, so please look out for an email from us.
              </h3>
              <h6>In the meantime, please check your inbox to confirm your email is</h6>
              <h6>
                <strong>{email}</strong>
              </h6>
              <div className="pl-1 pr-1 mt-4">
                <IonButton
                  color="light"
                  onClick={() => {
                    window.localStorage.clear();
                    //change this to website
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  <small>Home</small>
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Onboarded;
